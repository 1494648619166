import {
  computed, ref, Ref, SetupContext,
} from 'vue';
import * as ScormService from '@/services/api/lxp-scorm';
import { IScormContent } from '@/services/api/lxp-scorm/types';
import { useDi } from '@/plugins/di';
import { PlayerSessionStatus } from '@/domains/common';
import { ScormProgressStatus } from '@/domains/scorm/types';

interface IUseScormPlayerProps {
  playerSessionId: Ref<number>;
}

export default function useScormPlayer({ playerSessionId }: IUseScormPlayerProps, context: SetupContext<{}>) {
  const di = useDi();

  const isLoading = ref(false);

  const content = ref<IScormContent>();
  const isUpToDate = ref(true);

  const isSessionStarted = computed(() => content.value?.progress?.status !== PlayerSessionStatus.NEW);
  const isSessionCompleted = computed(() => content.value?.progress?.status === PlayerSessionStatus.COMPLETED);
  const isSessionActive = computed(() => content.value?.progress?.status === PlayerSessionStatus.ACTIVE);

  const fetchSession = async () => {
    try {
      isLoading.value = true;
      const { content: data } = await ScormService.scormSessionGet({ playerSessionId: playerSessionId.value });

      content.value = data;
    } catch (e: any) {
      console.error(e);

      di.notify.error({ content: e.message });
    } finally {
      isLoading.value = false;
    }
  };

  const onUpdateProgressHandler = async (data: string) => {
    if (isSessionCompleted.value || !playerSessionId.value) {
      return;
    }

    try {
      await ScormService.scormSessionUpdate({
        payload: {
          progressMeta: JSON.stringify(data),
        },
        playerSessionId: playerSessionId.value,
      });
    } catch (e: any) {
      console.error(e);
    }
  };

  const checkVersion = async () => {
    const { hasNewVersion = false } = await ScormService.scormCheckNewVersion({
      playerSessionId: playerSessionId.value,
    });

    isUpToDate.value = !hasNewVersion;
  };

  const onCompleteHandler = async () => {
    const currentSessionStatus = content.value!.progress.status;

    try {
      content.value!.progress.status = PlayerSessionStatus.COMPLETED;
      await ScormService.scormSessionComplete({ playerSessionId: playerSessionId.value });

      context.emit('completed');
    } catch (e: any) {
      console.error(e);
      content.value!.progress.status = currentSessionStatus;
    }
  };

  const onUpdateStatusHandler = async (status: ScormProgressStatus) => {
    if (
      (status === ScormProgressStatus.COMPLETED || status === ScormProgressStatus.PASSED)
        && !isSessionCompleted.value
    ) {
      onCompleteHandler();
    }
  };

  return {
    content,
    isLoading,
    isUpToDate,
    isSessionStarted,
    isSessionCompleted,
    isSessionActive,
    fetchSession,
    onUpdateProgressHandler,
    checkVersion,
    onCompleteHandler,
    onUpdateStatusHandler,
  };
}
