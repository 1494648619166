
import {
  defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import useScormPlayer from '@/domains/scorm/composables/useScormPlayer';
import Loader from '@/components/ui/Loader.vue';
import { AtomType } from '@/domains/atom';
import { Names } from '@/plugins/vue-router';
import { PlayerSessionStatus } from '@/domains/common';

export default defineComponent({
  name: 'AssignmentScormGateway',

  components: {
    Loader,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { playerSessionId } = toRefs(props);

    const router = useRouter();

    const {
      content, fetchSession,
    } = useScormPlayer({ playerSessionId }, context);

    onMounted(async () => {
      await fetchSession();

      if (!content.value?.status) return;

      if ([PlayerSessionStatus.NEW, PlayerSessionStatus.COMPLETED].includes(content.value?.progress?.status)) {
        await router.replace({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
          params: {
            atomType: AtomType.SCORM,
          },
        });
      } else {
        await router.replace({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_SCORM_PLAYER,
        });
      }
    });
  },
});
